<template>
  <FwbFooter footer-type="socialmedia" class="footer" :class="runtimeConfig.app.backgroundColorAlt">
    <div :class="$style.container">
      <div class="md:flex md:justify-between">
        <FwbFooterBrand
          href="/"
          src="/images/logo/otago.svg"
          alt="Otago Logo"
          name="Otago"
          class="tracking-wider text-blue-900"
        />
        <div class="grid grid-cols-3 gap-4 md:gap-16">
          <div>
            <h2>Company</h2>
            <FwbFooterLinkGroup>
              <FwbFooterLink href="/contact" component="router-link">Contact us</FwbFooterLink>
            </FwbFooterLinkGroup>
          </div>
          <div>
            <h2>Resources</h2>
            <FwbFooterLinkGroup>
              <FwbFooterLink href="/docs" component="router-link">Docs</FwbFooterLink>
              <FwbFooterLink href="/dashboard" component="router-link">Dashboard</FwbFooterLink>
            </FwbFooterLinkGroup>
          </div>
          <div>
            <h2>Legal</h2>
            <FwbFooterLinkGroup>
              <FwbFooterLink href="/legal/privacy-policy" component="router-link">Privacy Policy</FwbFooterLink>
              <FwbFooterLink href="/legal/terms" component="router-link">Terms & Conditions</FwbFooterLink>
            </FwbFooterLinkGroup>
          </div>
        </div>
      </div>
      <hr class="separator" />
      <div class="sm:flex sm:items-center sm:justify-between">
        <FwbFooterCopyright by="Otago" href="https://otago.dev/" copyright-message="All Rights Reserved." />
        <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <GithubLink class="w-6 h-6" />
        </div>
      </div>
    </div>
  </FwbFooter>
</template>

<script setup lang="ts">
import { FwbFooter, FwbFooterBrand, FwbFooterCopyright, FwbFooterLink, FwbFooterLinkGroup } from "flowbite-vue";

const runtimeConfig = useRuntimeConfig();
</script>

<style module>
@import url("~/assets/css/modules.css");
</style>

<style scoped>
.footer {
  h2 {
    @apply mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white;
  }

  ul {
    @apply flex flex-col items-start;

    li {
      @apply mb-4;
    }
  }

  .separator {
    @apply my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8;
  }
}
</style>
