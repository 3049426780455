<template>
  <header :class="runtimeConfig.app.backgroundColor">
    <FwbNavbar>
      <template #logo>
        <FwbNavbarLogo
          alt="Otago logo"
          image-url="/images/logo/otago.svg"
          link="/"
          class="tracking-wider text-blue-900"
          component="router-link"
          link-attr="to"
        >
          Otago
        </FwbNavbarLogo>
      </template>
      <template v-if="links.length > 0" #default="{ isShowMenu }">
        <FwbNavbarCollapse :is-show-menu="isShowMenu">
          <FwbNavbarLink
            v-for="link in links"
            :key="link.url"
            :link="link.url"
            :class="link.class"
            component="router-link"
            link-attr="to"
          >
            {{ link.label }}
          </FwbNavbarLink>
        </FwbNavbarCollapse>
      </template>
      <template #right-side>
        <div class="flex items-center">
          <GithubLink class="w-6 h-6 mr-4" />
          <BlueButton href="/dashboard" tag="router-link">Dashboard</BlueButton>
        </div>
      </template>
    </FwbNavbar>
  </header>
</template>

<script setup lang="ts">
import { FwbNavbar, FwbNavbarCollapse, FwbNavbarLink, FwbNavbarLogo } from "flowbite-vue";

const runtimeConfig = useRuntimeConfig();

defineProps<{
  links: { url: string; label: string; class?: string }[];
}>();
</script>

<style>
nav {
  @apply max-w-screen-xl mx-auto;

  a {
    @apply text-base font-semibold;
  }
}
</style>
